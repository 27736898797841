import React from 'react'
import { Rental, RentalPaymentResult } from '../../../domain/rental'
import { Stack, Typography } from '@mui/material'
import { Alert } from '@mui/lab'
import { Link } from 'react-router-dom'

interface Props {
  result: RentalPaymentResult
  rental: Rental
}

export const RentalsItemPaymentResult = ({ result, rental }: Props) => {
  return (
    <Stack spacing={2}>
      {result.chargeStatus === 'SUCCESS' && (
        <Alert severity="success">
          The customer was charged successfully.{' '}
          <Link
            to={`/dashboard/transaction/${result.data.paymentId}`}
            target="_blank"
            style={{ color: 'inherit', fontSize: 'inherit' }}
          >
            See transaction
          </Link>
        </Alert>
      )}
      {result.chargeStatus === 'FAILURE' && (
        <Alert severity="error">The customer wasn't charged successfully</Alert>
      )}
      {result.chargeStatus === 'NOT_STATED' && (
        <Alert severity="info">We didn't try to charge the customer</Alert>
      )}

      {result.walletStatus === 'SUCCESS' && (
        <Alert severity="success">
          The wallet was adjusted successfully.{' '}
          <Link
            to={`/dashboard/ledger/${rental.userId}/${rental.cityId}`}
            target="_blank"
            style={{ color: 'inherit', fontSize: 'inherit' }}
          >
            See ledger
          </Link>
        </Alert>
      )}
      {result.walletStatus === 'FAILURE' && (
        <Alert severity="error">The wallet wasn't adjusted successfully</Alert>
      )}
      {result.walletStatus === 'NOT_STATED' && (
        <Alert severity="info">We didn't try to adjust the wallet</Alert>
      )}

      {result.emailStatus === 'SUCCESS' && (
        <Alert severity="success">The email was send successfully</Alert>
      )}
      {result.emailStatus === 'FAILURE' && (
        <Alert severity="error">The email wasn't send successfully</Alert>
      )}
      {result.emailStatus === 'NOT_STATED' && (
        <Alert severity="info">We didn't try to send the email</Alert>
      )}

      {!!result.errorMessages.length && <Typography color="error">Errors</Typography>}

      {result.errorMessages.map((error) => {
        return (
          <Alert severity="error" key={error}>
            {error}
          </Alert>
        )
      })}
    </Stack>
  )
}
