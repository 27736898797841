import { Box, Typography } from '@mui/material'
import { ImageNotSupported } from '@mui/icons-material'

export const NoImage = () => {
  return (
    <Box
      sx={(t) => ({
        width: '100%',
        height: '100%',
        background: t.palette.grey.A200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      <Box>
        <ImageNotSupported sx={{ fontSize: 100 }} />

        <Typography variant="h6" align="center">
          No image
        </Typography>
      </Box>
    </Box>
  )
}
