import { apiClient } from '../apiClient'
import {
  ServiceAccount,
  ServiceAccountCreate,
  ServiceAccountUpdate,
  ServiceAccountUpdateKeys,
} from '../../domain/service-account'

export const getServiceAccounts = (): Promise<ServiceAccount[]> => {
  return apiClient.get(`/payment-service/dashboard/payments/service/accounts`).then((r) => r.data)
}

export const createServiceAccount = (dto: ServiceAccountCreate): Promise<ServiceAccount> => {
  return apiClient
    .post(`/payment-service/dashboard/payments/service/accounts`, dto)
    .then((r) => r.data)
}

export const updateServiceAccount = (
  key: string,
  dto: ServiceAccountUpdate
): Promise<ServiceAccount> => {
  return apiClient
    .patch(`/payment-service/dashboard/payments/service/accounts/${key}`, dto)
    .then((r) => r.data)
}

export const updateServiceAccountKeys = (
  key: string,
  dto: ServiceAccountUpdateKeys
): Promise<ServiceAccount> => {
  return apiClient
    .patch(`/payment-service/dashboard/payments/service/accounts/${key}/keys`, dto)
    .then((r) => r.data)
}

export const deleteServiceAccount = (key: string): Promise<void> => {
  return apiClient.delete(`/payment-service/dashboard/payments/service/accounts/${key}`)
}
