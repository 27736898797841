import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { Box, Button, ButtonGroup, Paper, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import {
  ServiceAccount,
  ServiceAccountCreate,
  ServiceAccountUpdate,
} from '../../domain/service-account'
import { useShowToast } from '../../hooks'
import { useSelector } from 'react-redux'
import Autocomplete from '@mui/lab/Autocomplete'
import {
  createServiceAccount,
  updateServiceAccount,
} from '../../apis/service-account/service-account-apis'

interface Props {
  onClose: (shouldReload: boolean) => void
  account?: ServiceAccount
}

interface FormData {
  key: string
  secretKey: string
  publishableKey: string
  webhookSignature: string
  currency: string
  minAmount: string
  cities: any[]
  whiteLabel: any
  methodTypes: string
}

export const ServiceAccountsAdd = ({ onClose, account }: Props) => {
  const { whiteLabels = [], citiesList = [] } = useSelector((s: any) => s.cities)
  const {
    formState: { errors, isValid },
    watch,
    register,
    setValue,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      cities: [],
      key: account?.key || '',
      secretKey: account?.key || '',
      publishableKey: account?.key || '',
      webhookSignature: account?.key || '',
      whiteLabel: null,
      methodTypes: '',
    },
  })
  const showToast = useShowToast()
  const isEdit = !!account
  const [loading, setLoading] = useState(false)
  const formValues = watch()
  const cities = !!formValues.whiteLabel
    ? whiteLabels.find((i: any) => i.id === formValues.whiteLabel.id)?.cities
    : citiesList

  const selectedCityIds = formValues.cities.map((c) => c.id)

  const createAccount = () => {
    const dto: ServiceAccountCreate = {
      key: formValues.key,
      secretKey: formValues.secretKey,
      publishableKey: formValues.publishableKey,
      webhookSignature: formValues.webhookSignature,
      currency: formValues.currency,
      minAmount: parseInt(formValues.minAmount),
      cityIds: selectedCityIds,
      whiteLabelId: formValues.whiteLabel?.id || null,
      methodTypes: formValues.methodTypes.split(',').map((s) => s.trim()),
    }

    setLoading(true)
    createServiceAccount(dto)
      .then(() => {
        onClose(true)
      })
      .catch(() => {
        showToast('Failed to create service account', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateAccount = () => {
    const dto: ServiceAccountUpdate = {
      currency: formValues.currency,
      minAmount: parseInt(formValues.minAmount),
      cityIds: selectedCityIds,
      whiteLabelId: formValues.whiteLabel?.id,
      methodTypes: formValues.methodTypes.split(',').map((s) => s.trim()),
    }

    if (account) {
      setLoading(true)
      updateServiceAccount(account.key, dto)
        .then(() => {
          onClose(true)
        })
        .catch(() => {
          showToast('Failed to update service account', 'error')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleSubmit = () => {
    isEdit ? updateAccount() : createAccount()
  }

  useEffect(() => {
    if (account && cities && whiteLabels) {
      setValue('currency', account.currency)
      setValue('minAmount', account.minAmount.toString())
      setValue(
        'whiteLabel',
        whiteLabels.find((wl: any) => wl.id === account.whiteLabelId)
      )
      setValue(
        'cities',
        cities.filter((c: any) => account.cityIds?.includes(c.id))
      )
      setValue('methodTypes', account.methodTypes.join(', '))
    }
  }, [account?.key, whiteLabels, cities])

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '80%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            {!isEdit && <Typography variant="h5">Add Service Account</Typography>}
            {isEdit && (
              <Typography variant="h5">
                Edit Service Account <strong>{account?.key}</strong>
              </Typography>
            )}

            <Box flexGrow={1}>
              <Stack spacing={2}>
                {!isEdit && (
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      label="Key"
                      {...register('key', {
                        required: 'The field is required',
                      })}
                      error={!!errors.key}
                      helperText={errors.key?.message}
                    />

                    <TextField
                      fullWidth
                      label="Secret Key"
                      {...register('secretKey', {
                        required: 'The field is required',
                      })}
                      error={!!errors.secretKey}
                      helperText={errors.secretKey?.message}
                    />

                    <TextField
                      fullWidth
                      label="Publishable Key"
                      {...register('publishableKey', {
                        required: 'The field is required',
                      })}
                      error={!!errors.publishableKey}
                      helperText={errors.publishableKey?.message}
                    />

                    <TextField
                      fullWidth
                      label="Webhook Signature"
                      {...register('webhookSignature', {
                        required: 'The field is required',
                      })}
                      error={!!errors.webhookSignature}
                      helperText={errors.webhookSignature?.message}
                    />
                  </Stack>
                )}

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    fullWidth
                    label="Currency"
                    {...register('currency', {
                      required: 'The field is required',
                    })}
                    error={!!errors.currency}
                    helperText={errors.currency?.message}
                  />

                  <TextField
                    fullWidth
                    label="Min Amount"
                    type="number"
                    {...register('minAmount', {
                      required: 'The field is required',
                    })}
                    error={!!errors.minAmount}
                    helperText={errors.minAmount?.message}
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Autocomplete
                    fullWidth
                    multiple={false}
                    value={formValues.whiteLabel}
                    onChange={(event, value) => {
                      setValue('whiteLabel', value)
                      setValue('cities', [])
                    }}
                    options={whiteLabels}
                    getOptionLabel={(o) => o.name}
                    renderInput={(params) => <TextField {...params} label="White Label" />}
                  />

                  <Autocomplete
                    fullWidth
                    multiple={true}
                    value={formValues.cities}
                    onChange={(event, value) => {
                      setValue('cities', value)
                    }}
                    options={cities}
                    getOptionLabel={(o) => o.name}
                    getOptionDisabled={(o) => selectedCityIds.includes(o.id)}
                    renderInput={(params) => <TextField {...params} label="Cities" />}
                  />

                  <TextField
                    fullWidth
                    label="Method Types"
                    {...register('methodTypes', {
                      required: 'The field is required',
                    })}
                    error={!!errors.methodTypes}
                    helperText={errors.methodTypes?.message}
                  />
                </Box>
              </Stack>
            </Box>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  disabled={!isValid}
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
