import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { Box, Button, ButtonGroup, Paper, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { ServiceAccountUpdateKeys } from '../../domain/service-account'
import { useShowToast } from '../../hooks'
import { updateServiceAccountKeys } from '../../apis/service-account/service-account-apis'

interface Props {
  keyToEdit: string
  onClose: (shouldReload: boolean) => void
}

interface FormData {
  key: string
  secretKey: string
  publishableKey: string
  webhookSignature: string
}

export const ServiceAccountsUpdateKeys = ({ keyToEdit, onClose }: Props) => {
  const {
    formState: { errors, isValid },
    watch,
    register,
  } = useForm<FormData>({
    mode: 'all',
  })
  const showToast = useShowToast()
  const [loading, setLoading] = useState(false)
  const formValues = watch()

  const updateAccount = () => {
    const dto: ServiceAccountUpdateKeys = {
      key: formValues.key,
      secretKey: formValues.secretKey,
      publishableKey: formValues.publishableKey,
      webhookSignature: formValues.webhookSignature,
    }

    setLoading(true)
    updateServiceAccountKeys(keyToEdit, dto)
      .then(() => {
        onClose(true)
      })
      .catch(() => {
        showToast('Failed to update service account', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal open>
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Paper sx={{ width: '80%', padding: '20px' }}>
          <Box height="100%" display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5">
              Edit Service Account <strong>{keyToEdit}</strong> Keys
            </Typography>

            <Box flexGrow={1}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  label="Key"
                  {...register('key')}
                  error={!!errors.key}
                  helperText={errors.key?.message}
                />

                <TextField
                  fullWidth
                  label="Secret Key"
                  {...register('secretKey')}
                  error={!!errors.secretKey}
                  helperText={errors.secretKey?.message}
                />

                <TextField
                  fullWidth
                  label="Publishable Key"
                  {...register('publishableKey')}
                  error={!!errors.publishableKey}
                  helperText={errors.publishableKey?.message}
                />

                <TextField
                  fullWidth
                  label="Webhook Signature"
                  {...register('webhookSignature')}
                  error={!!errors.webhookSignature}
                  helperText={errors.webhookSignature?.message}
                />
              </Stack>
            </Box>

            <Box textAlign="right">
              <ButtonGroup>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  disabled={!isValid}
                  loading={loading}
                  onClick={() => updateAccount()}
                >
                  Submit
                </LoadingButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}
