import React, { useEffect, useState } from 'react'
import { Rental, RentalPaymentResult } from '../../../domain/rental'
import { Box, Card, CardContent, CardMedia, Modal, Stack, Typography } from '@mui/material'
import { getPricingPlans } from '../../../apis/rental/city-apis'
import { useSelector } from 'react-redux'
import { constructImageDirectory } from '../../../utils/utils'
import * as Storage from 'firebase/storage'
import TextField from '@mui/material/TextField/TextField'
import Button from '@mui/material/Button'
import { payForRental } from '../../../apis/rental/rental-apis'
import CircularProgress from '@mui/material/CircularProgress'
import { NoImage } from '../../no-image/NoImage'
import { usePaymentEmailStr } from '../../../hooks/constant-hooks'
import { getUser } from '../../../apis/user/user-apis'
import { User } from '../../../domain/user'
import { Alert } from '@mui/lab'
import { Link } from 'react-router-dom'
import { RentalsItemPaymentResult } from './RentalsItemPaymentResult'

interface Props {
  rental: Rental
  onClose: () => void
}

export const RentalsItemPayment = ({ rental, onClose }: Props) => {
  const allCities = useSelector((s: any) => s.cities.citiesList)
  const [imageUrl, setImageUrl] = useState('')
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState('')
  const [emailStr, setEmailStr] = useState(usePaymentEmailStr())
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const [result, setResult] = useState<RentalPaymentResult | null>(null)
  const couldSendEmail = user?.email

  const onSubmit = () => {
    setLoading(true)
    payForRental(rental.id, { amount: amount * 100, description, emailStr })
      .then((result) => {
        setResult(result)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const cityCode = allCities.find((c: any) => c.id === rental.cityId)?.code

    if (rental?.cityId && cityCode) {
      getPricingPlans(rental.cityId).then((plans) => {
        const directory = constructImageDirectory({
          cityCode,
          pricingPlanType: plans.find((p) => p.id === rental.planId)?.type,
          id: rental.id,
        })

        const storage = Storage.getStorage()
        const storageRef = Storage.ref(storage, directory)

        Storage.list(storageRef, { maxResults: 1 })
          .then(({ items: [item] }) => {
            return item ? Storage.getDownloadURL(item) : ''
          })
          .then((url) => {
            setImageUrl(url)
          })
      })
    }
  }, [rental])

  useEffect(() => {
    if (rental?.userId) {
      getUser(rental.userId).then((result) => {
        setUser(result)
      })
    } else {
      setUser(null)
    }
  }, [rental])

  return (
    <Modal className={'modalContainer'} open={true} onClose={() => onClose()}>
      <Card variant="outlined" sx={{ width: '80vw', height: '90vh', display: 'flex' }}>
        {imageUrl ? (
          <CardMedia component="img" src={imageUrl} sx={{ width: '40%' }} />
        ) : (
          <CardMedia sx={{ width: '40%' }}>
            <NoImage />
          </CardMedia>
        )}
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            <Stack spacing={3}>
              <Stack spacing={2}>
                <Typography variant="h4" style={{ margin: 0 }}>
                  Make a payment for {rental.id}
                </Typography>
                {!!user && (
                  <Typography variant="h6" style={{ margin: 0 }}>
                    <Link
                      to={`/dashboard/user/${rental.userId}`}
                      target="_blank"
                      style={{ color: 'blue' }}
                    >
                      {user.fullName || user.id}
                    </Link>{' '}
                    will be charged
                  </Typography>
                )}
              </Stack>

              {!result && (
                <Stack spacing={3}>
                  <Box sx={{ display: 'flex', gap: 3 }}>
                    <TextField
                      value={amount}
                      onChange={(e) => setAmount(parseFloat(e.target.value))}
                      label="Amount"
                      type="number"
                      sx={{ width: '40%' }}
                    />
                    <TextField
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      fullWidth={true}
                      label="Description"
                    />
                  </Box>

                  <Stack spacing={1}>
                    <TextField
                      value={emailStr}
                      onChange={(e) => setEmailStr(e.target.value)}
                      multiline={true}
                      rows={15}
                      fullWidth={true}
                      label="Email (Optional)"
                      disabled={!couldSendEmail}
                    />

                    {couldSendEmail && (
                      <Alert severity="info">
                        The email will be sent to <u>{user?.email}</u>
                      </Alert>
                    )}
                    {!couldSendEmail && (
                      <Alert severity="warning">
                        The email couldn't be sent because the user doesn't have an email address
                      </Alert>
                    )}
                  </Stack>
                </Stack>
              )}

              {!!result && <RentalsItemPaymentResult result={result} rental={rental} />}
            </Stack>

            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              {!result && (
                <Button variant="contained" disabled={!amount || !description} onClick={onSubmit}>
                  {loading ? (
                    <CircularProgress size={20} sx={{ color: 'white' }} />
                  ) : (
                    <>Capture {amount ? `${amount} ${rental.currency}` : ''}</>
                  )}
                </Button>
              )}
              {!!result && (
                <Button variant="contained" onClick={() => onClose()}>
                  Close
                </Button>
              )}
            </Box>
          </CardContent>
        </Box>
      </Card>
    </Modal>
  )
}
