import { apiClientJSON } from '../apiClient'
import { User } from '../../domain/user'

export const getUser = (userId: number): Promise<User> => {
  return apiClientJSON.get(`/account-service/dashboard/users/${userId}`).then((r) => r.data)
}

export const getUsersList = (search: string): Promise<User[]> => {
  return apiClientJSON
    .get(`/account-service/dashboard/users`, {
      params: {
        page: 0,
        size: 20,
        search,
      },
    })
    .then((r) => r.data?.content || [])
}
