import { Box, ImageListItem, ImageListItemBar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Rental } from '../../domain/rental'
import { constructImageDirectory } from '../../utils/utils'
import * as Storage from 'firebase/storage'
import { PricingPlan } from '../../domain/city'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { DASHBOARD_RENTALS } from '../../constants/routes'
import { useDispatch } from 'react-redux'
import { setSearchValue } from '../../actions/screen'
import { NoImage } from '../../components/no-image/NoImage'

interface Props {
  rental: Rental
  cityCode: string
  plans: PricingPlan[]
}

export const RentalsGalleryItem = ({ rental, cityCode, plans }: Props) => {
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState('')
  const [showPreview, setShowPreview] = useState(false)
  const dispatch = useDispatch()

  const navigateToRental = () => {
    dispatch(setSearchValue(rental.id))
    window.open(`${DASHBOARD_RENTALS}?search=${rental.id}`, '_blank')
  }

  useEffect(() => {
    setLoading(true)

    const directory = constructImageDirectory({
      cityCode,
      pricingPlanType: plans.find((p) => p.id === rental.planId)?.type,
      id: rental.id,
    })

    const storage = Storage.getStorage()
    const storageRef = Storage.ref(storage, directory)

    Storage.list(storageRef, { maxResults: 1 })
      .then(({ items: [item] }) => {
        return item ? Storage.getDownloadURL(item) : ''
      })
      .then((url) => {
        setUrl(url)
      })
      .finally(() => setLoading(false))
  }, [])

  if (loading) return <CircularProgress size={50} />

  return (
    <>
      <ImageListItem>
        {url ? (
          <img src={url} alt="" />
        ) : (
          <Box sx={{ height: 300 }}>
            <NoImage />
          </Box>
        )}
        <ImageListItemBar
          title={
            <Button variant="contained" onClick={navigateToRental}>
              {rental.id}
            </Button>
          }
          actionIcon={url ? <Button onClick={() => setShowPreview(true)}>View</Button> : null}
        />
      </ImageListItem>

      <Dialog open={showPreview} onClose={() => setShowPreview(false)} maxWidth="md">
        <DialogContent>
          {url && <img src={url} style={{ width: '100%', height: 'auto' }} />}
        </DialogContent>
      </Dialog>
    </>
  )
}
