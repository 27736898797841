import { Box, Divider, Theme, Typography } from '@mui/material'
import React, { memo, useCallback } from 'react'
import StarIcon from '@mui/icons-material/Star'
import { Rating } from '../../../domain/rating'
import { getJourneyRating } from '../../../apis/rating/rating-apis'
import TextField from '@mui/material/TextField/TextField'
import Modal from '@mui/material/Modal'

interface Props {
  rental: any
  onClick: () => void
}

const RentalsItemRating = ({ rental: { ratingGrade, id, ...rest }, onClick }: Props) => {
  const amount = Math.ceil(ratingGrade / 20)
  const [detailsOpen, setDetailsOpen] = React.useState<boolean>(false)
  const [rating, setRating] = React.useState<Rating | null>(null)

  const getColor = useCallback(
    (theme: Theme) => {
      switch (amount) {
        case 5:
          return theme.palette.success.main
        case 4:
          return theme.palette.success.light
        case 3:
          return theme.palette.warning.main
        case 2:
          return theme.palette.error.light
        case 1:
        default:
          return theme.palette.error.main
      }
    },
    [amount]
  )

  const openDetails = () => {
    setDetailsOpen(true)

    if (!rating) {
      getJourneyRating(id).then((r) => {
        setRating(r)
      })
    }
  }

  const closeDetails = () => {
    setDetailsOpen(false)
  }

  const renderMultiStars = () => {
    return Array.from({ length: amount }).map((_, i) => (
      <StarIcon key={i} sx={(t) => ({ color: getColor(t) })} />
    ))
  }

  if (!ratingGrade) {
    return <>-</>
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}
        onClick={openDetails}
      >
        <StarIcon sx={(t) => ({ color: getColor(t) })} />
        <Box sx={(t) => ({ fontSize: 18, fontWeight: 'bold', color: getColor(t) })}>{amount}</Box>
      </Box>
      {detailsOpen && (
        <Modal
          open={true}
          onClose={closeDetails}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box sx={{ background: 'white', width: 700, padding: 3, borderRadius: 3 }}>
            <Typography variant="h4">
              Rental {id} {renderMultiStars()}
            </Typography>

            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              Comment
            </Typography>
            <TextField
              fullWidth={true}
              multiline={true}
              disabled={true}
              defaultValue={rating?.comment}
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default memo(RentalsItemRating, (p) => p.rental.rentalGrade)
