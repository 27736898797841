import { apiClient } from '../apiClient'
import { Rental, RentalListParams, RentalPayment, RentalPaymentResult } from '../../domain/rental'

export const getRentals = (params: RentalListParams): Promise<Rental[]> => {
  return apiClient
    .get(`/journey-service/dashboard/journeys`, { params })
    .then((r) => r.data.content)
}

export const payForRental = (
  rentalId: number,
  data: RentalPayment
): Promise<RentalPaymentResult> => {
  return apiClient
    .post(`/journey-service/dashboard/journeys/${rentalId}/payment/custom`, data)
    .then((r) => r.data)
}
