import { Box, ImageList } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import { getRentals } from '../../apis/rental/rental-apis'
import { useCity } from '../../hooks'
import { Rental, RentalListParams } from '../../domain/rental'
import { getPricingPlans } from '../../apis/rental/city-apis'
import { PricingPlan } from '../../domain/city'
import { RentalsGalleryItem } from './RentalsGalleryItem'
import { useSearchParams } from 'react-router-dom'
import { Alert } from '@mui/lab'

const DATE_SEARCH_PARAM_KEY = 'date'
const DATE_SEARCH_PARAM_FORMAT = 'YYYY-MM-DD'

export const RentalsGallery = () => {
  const { selectedCity, savedCityFullInfo } = useCity()
  const cityId = parseInt(selectedCity)
  const tomorrowRef = useRef<Moment>(moment().add(1, 'days'))
  const [rentals, setRentals] = useState<Rental[]>([])
  const [plans, setPlans] = useState<PricingPlan[]>([])

  const [searchParams, setSearchParams] = useSearchParams()
  const dateStr = searchParams.get(DATE_SEARCH_PARAM_KEY)

  const updateDate = (value: moment.Moment | null) => {
    if (value) {
      searchParams.set(DATE_SEARCH_PARAM_KEY, value.format(DATE_SEARCH_PARAM_FORMAT))
    } else {
      searchParams.delete(DATE_SEARCH_PARAM_KEY)
    }

    setSearchParams(searchParams)
  }

  const params = useMemo<RentalListParams>(() => {
    const date = dateStr ? moment(dateStr, DATE_SEARCH_PARAM_FORMAT, true) : moment()

    return {
      cityId,
      size: 1000,
      endTimeFrom: date.startOf('day').toISOString(),
      endTimeTo: date.endOf('day').toISOString(),
    }
  }, [cityId, dateStr])

  useEffect(() => {
    if (params.cityId) {
      getRentals(params).then((result) => {
        setRentals(result)
      })

      getPricingPlans(cityId).then((result) => {
        setPlans(result)
      })
    } else {
      setRentals([])
      setPlans([])
    }
  }, [params])

  const isImagesShown = useMemo(() => {
    return !!savedCityFullInfo && !!rentals.length && !!plans.length
  }, [savedCityFullInfo, rentals, plans])

  return (
    <Box sx={{ padding: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <p className="dashboardTitle">Rentals Gallery</p>

        <DatePicker
          label="Date"
          maxDate={tomorrowRef.current}
          value={moment(params.endTimeFrom)}
          onChange={updateDate}
        />
      </Box>

      {!savedCityFullInfo && <Alert severity="warning">You must select a city to see photos</Alert>}
      {!!savedCityFullInfo && !rentals.length && (
        <Alert severity="info">No rentals found at this date</Alert>
      )}

      {isImagesShown && (
        <ImageList cols={6}>
          {rentals.map((rental) => (
            <RentalsGalleryItem
              rental={rental}
              cityCode={savedCityFullInfo.code}
              plans={plans}
              key={rental.id}
            />
          ))}
        </ImageList>
      )}
    </Box>
  )
}
